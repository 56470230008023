<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="address"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :searchable="true"
      @search-change="handleSearch"
      :internal-search="false"
      :disabled="isDisabledV2"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} - {{ props.option.contact_name }} - {{ props.option.address }}</span
          >
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} - {{ props.option.contact_name }} - {{ props.option.address }}</span
          >
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";
const MasterRepository = RepositoryFactory.get("master");
export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    formDatas: null,
  },
  data() {
    return {
      options: [],
      selected: null,
      isDisabledV2:false,
    };
  },
  mounted() {
    this.isDisabledV2 = this.isDisabled
    if (this.isGetData) {
      this.getDataShipTo();
    }
  },
  methods: {
    setDisabled(isDisabled) {
      this.isDisabledV2 = isDisabled;
      // this.isDisabled = isDisabled;
    },
    handleSearch: _.debounce(function (query) {
      if (query.length >= 2) {
        let params_ = { search: query, is_default: false, };
        if (this.formDatas != null){
          if (this.formDatas.territory_id != 0) {
            params_ = { search: query, territory_id: this.formDatas.territory_id, so_type:"sr", is_default: false, };
          }
          if(this.formDatas.customer_data.customer_id){
            params_ = { search: query, territory_id: this.formDatas.territory_id, customer_id: this.formDatas.customer_data.customer_id, so_type:"sr", is_default: true, };
          }
        }
        this.getDataShipTo({ params: params_ });
      }
    }, 1000),
    async setSelectedData(data) {
      this.selected = data;
    },
    async setContactName(contact_name) {
      if (contact_name&&this.selected!=null) {
        this.selected.contact_name = contact_name;
      }
    },
    async setAddress(address) {
      if (address&&this.selected!=null) {
        this.selected.address = address;
      }
    },
    async toggleDisabled() {
      this.isDisabledV2= !this.isDisabledV2;
      // this.isDisabled = !this.isDisabled;
    },
    async getDataShipTo(params) {
        if (!params) {
          params = {}//biar nggak error "Cannot set properties of undefined"
        }
        params.so_type = params.so_type != undefined ? params.so_type : "sr";
        this.$vs.loading();
        console.log("params", params)
        try {
        const resp = await MasterRepository.getCustomerAddressShipToSr(params);
        if (resp.code == 200) {
          if (resp.data.records.length == 1 && params.params.is_default) {
            this.selected = resp.data.records[0];
            this.options = resp.data.records;
          } else {
            this.options = resp.data.records;
          }
          console.log(this.formDatas.so_types == 'with_so_reference' || this.formDatas.isDetail == 1 || !this.formDatas.isEdit)
          console.log(this.formDatas.so_types == 'with_so_reference')
          console.log(this.formDatas.isDetail == 1)
          console.log(this.formDatas.isEdit)
          console.log(!this.formDatas.isEdit)
          if(this.formDatas.so_types == 'with_so_reference' || this.formDatas.isDetail == 1 || !this.formDatas.isEdit){
            this.toggleDisabled();
          } else {
            this.setDisabled(false);
          }

          if (this.formDatas.isDetail == 0 && this.formDatas.so_types != 'with_so_reference') {
            this.setDisabled(false);
          }
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching customer address ship to",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log("Error while fetching customer address ship to", error);
      }
      this.$vs.loading.close();
    },
    async resetForm() {
      this.selected = null
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
    isDisabled:{
      handler:function(val){
        console.log("val watch isDisabled", val);
        console.log("isDisabled", this.isDisabled);
        this.isDisabledV2 = val
      }
    },
  },
};
</script>
