<style>
td.td.vs-table--td .multiselect__content-wrapper {
	position: relative;
}
</style>

<template>
	<vx-card title="Sales Return Form" v-if="!isLoading">
		<vs-row class="mb-4">
			<!-- choose SO type -->
			<vs-col vs-w="6">
				<div class="mb-4">
					<label class="vs-input--label">Choose SO type</label>
					<multiselect v-model="so_type_object_selected" :options="so_type_object" track-by="code" label="name"
						:max-height="125" :allow-empty="true" :select-label="''" deselect-label=""
						:disabled="form.isDetail == 1 || form.isEdit == true">
						<span slot="noResult">Oops! No data found</span>
						<template slot="singleLabel" slot-scope="props_">
							<span class="option__desc">
								<span class="option__title">{{ props_.option.name }}</span>
							</span>
						</template>

						<template slot="option" slot-scope="props_">
							<div class="option__desc">
								<span class="option__title">{{ props_.option.name }}</span>
							</div>
						</template>
					</multiselect>
				</div>
				<div v-if="form.so_types == 'with_so_reference'" class="mb-2">
					<label class="vs-input--label">Choose Return type</label>
					<div class="vx-col vx-row sm:w-2/3 w-full pl-2  pt-2">
						<div class="vx-col sm:w-1/4 mt-2">
							<input type="radio" value="1" v-model="return_type_str">
							<label> Full</label>
						</div>
						<div class="vx-col sm:w-1/4 mt-2">
							<input type="radio" value="0" v-model="return_type_str">
							<label> Partial</label>
						</div>
					</div>
				</div>
			</vs-col>
		</vs-row>

		<vs-row class="mb-4" vs-type="flex" vs-justify="space-between">
			<!-- customer invoice data -->
			<vs-col vs-w="6">
				<customer-invoice-data v-if="!isLoading" @data="setCustomerInvoiceData"
					:isDisabled="isDisabledFormInvoice" :form="form" />
			</vs-col>

			<!-- pickup request -->
			<vs-col vs-w="5">
				<pickup-request :form="form" v-if="!isLoading" />
			</vs-col>
		</vs-row>

		<!-- customer data -->
		<vs-row class="mb-4"  vs-type="flex" vs-justify="space-between">
			<vs-col vs-w="12">
				<customer-data ref="customerData" :form="form" :terr_params="terr_params" v-if="!isLoading" @selectSalesman="setSelectSalesman" :onetimeCustomer="onetimeCustomer" @is_onetime="setOneTime"/>
			</vs-col>
		</vs-row>

		<!-- customer return data -->
		<vs-row class="mb-4">
			<vs-col vs-w="6">
				<customer-return-data :form="form" v-if="!isLoading" />
			</vs-col>
		</vs-row>

		<!-- return line item data -->
		<vs-row class="mb-4">
			<vs-col vs-w="12">
				<return-line-item-data v-if="!isLoading" :isWithSoReference="form.so_types == 'with_so_reference'"
					:form="form" :showBtnSearch="form.so_types == 'without_so_reference'" :return_type_str="return_type_str" />
					<!-- :params="this.reason_params" -->
				<!-- <p class="text-xl text-bold">List Items</p>
        <hr />
        <TableItemV2
          :form="form"
          :isWithSoReference="form.so_types == 'with_so_reference'"
        /> -->
			</vs-col>
		</vs-row>

		<vs-row>
			<vs-col>
				<div class="flex gap-2">
					<vs-button color="danger" type="filled" class="my-4" @click="clickBack">Cancel</vs-button>
					<vs-button color="warning" type="filled" class="my-4" icon="save" v-show="form.isDetail == 0"
						@click="clickSaveAsDraft">Save As Draft</vs-button>
					<!-- Req Rivero - change wording "Save" to "Send for Approval" -->
					<vs-button color="primary" type="filled" class="my-4" icon="save" v-show="form.isDetail == 0"
						@click="clickSave">Send for Approval</vs-button>
					
				</div>
			</vs-col>
		</vs-row>
	</vx-card>
</template>

<script>
import CustomerInvoiceData from "./_components/customer-invoice-data.vue";
import PickupRequest from "./_components/pickup-request.vue";
import CustomerData from "./_components/customer-data-v2.vue";
import CustomerReturnData from "./_components/customer-return-data.vue";
import ReturnLineItemData from "./_components/return-line-item-data.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import TableItemV2 from "@/views/oms/customer-return/sales-return-v2/_components/TableItemV2/index.vue";
const SalesReturnV2Repo = RepositoryFactory.get("salesReturnV2");

export default {
	props: [
      "dataSR", "srID", "dataFil", "isDetailForm",
    ],
	components: {
		CustomerInvoiceData,
		PickupRequest,
		CustomerData,
		CustomerReturnData,
		ReturnLineItemData,
		TableItemV2,
	},
	name: "sales-return-v2-form",
	async mounted() {
		console.log("this.dataSR", this.dataSR);
		console.log("this.srID", this.srID);
		console.log("this.dataFil", this.dataFil);
		console.log("this.isDetailForm", this.isDetailForm);

		const id = this.$route.query.id ? this.$route.query.id : (this.srID ? this.srID : 0 );

		console.log("id", id);

		if (this.$userLogin.role[0].Group == "Sales") {
			this.terr_params = { 
				params : {
					id : this.$userLogin.territory_id,
				},
			};
		}
		
		if (id != 0) {
			this.isLoading = true;
			await this.$vs.loading();
			try {
				const resp = await SalesReturnV2Repo.getDetail(id);
				if (resp.code == 200) {
					await this.setForm(resp.data);
					this.terr_params = { 
						params : {
							id : resp.data.customer_return_data.territory_id,
						},
					};
				} else {
					await this.$vs.notify({
						title: "Error",
						text: resp.message,
						color: "danger",
					});
				}
			} catch (error) {
				await this.$vs.notify({
					title: "Error",
					text: error.message,
					color: "danger",
				});
			} finally {
				this.isLoading = false;
				await this.$vs.loading.close();
			}
		}
	},
	data() {
		return {
			return_type_str: "1", // MIX2-5447 | 0 untuk partial, 1 untuk full. Khusus SO with refference. 
			isLoading: false,
			so_types: [
				{
					code: "with_so_reference",
					name: "With SO Reference",
				},
				{
					code: "without_so_reference",
					name: "Without SO Reference",
				},
			],
			so_type_object_selected: null,
			so_type_object: [
				{
					code: "with_so_reference",
					name: "With SO Reference",
				},
				{
					code: "without_so_reference",
					name: "Without SO Reference",
				},
			],
			form: {
				isEdit: false,
				isDetail: 0,
				so_types: null,
				customer_invoice_data: {
					customer_invoice_id: null,
					sales_id: null,
					item_unit_ids: [],
					sales_order_lines: [],
					sales_order_id: null,
					invoice_lines: [],
					invoice_id: null,
					sales_order_data: null,
				},
				customer_data: {
					customer_id: [],
					selected_customer_id: null,
					customer_name: null,
					pickup_from_ship_to: null,
					selected_customer: null,
					selected_pickup_from_ship_to: null,
					customer_sold_to: null,
					pricing_group_id: null,
					customer_group_id_1: null,
					customer_group_id_2: null,
					customer_group_id_3: null,
				},
				customer_return_data: {
					customer_return_id: null,
					reference_code: null,
					reason_code: null,
					customer_return_type: null,
					territory_warehouse: null,
					note: null,
					attachments: null,
					external_code: null,
					tax_id: null,
					territory_warehouse_id: null,
					reason_id: null,
					customer_return_date: new Date().toISOString().split("T")[0],
					customer_ship_to: null,
				},
				pickup_request: {
					is_pickup: null,
					pickup_date: null,
				},
				return_line_item_data: [],
				summary_data: {
					total_list_price: 0,
					total_adj_price: 0,
					total_discount: 0,
					total_tax: 0,
					total_net: 0,
					total_charges: 0,
					total_return: 0,
				},
				territory_id: 0,
				sales_personal_id: null,
				srID: this.srID,
			},
			isDisabledFormInvoice: true,
			isDisabledSoType: false,
			saveAsDraft: false,
			terr_params: null,
			customerOnetimeID:0,
			onetimeCustomer:{
				id:0,
				territory_id:0,
				address:"",
				postalCode:"",
				countryName:"",
				provinceName:"",
				cityName:"",
				districtName:"",
				subdistrictName:"",
				name:"",
				contact_person:"",
				nik:"",
				phone:"",
				domisili:"",
				npwp_type:"",
				npwp:"",
				npwp_name:"",
				sppkp:"",
				tax_address:"",
				readonly:false,
			},
			isOnetime:false,
		};
	},
	methods: {
		disabledIsDetailActive() {
			if (this.form.isDetail == 1) {
				return true;
			}
			return false;
		},
		async setForm(data) {
			const is_detail = this.$route.query.detail;
			if (is_detail == 1) {
				this.form.isDetail = 1;
			}

            if (this.isDetailForm) {
				this.form.isDetail = 1;
            }

			let form = {
				isEdit: this.form.isDetail == 1 ? false : true,
				isDetail: this.form.isDetail,
				so_types: data.so_type,
				customer_invoice_data: {
					invoice_code: data.customer_invoice_data.invoice_code,
					sales_id_name: data.customer_invoice_data.sales_id_name,
					invoice_id: data.customer_invoice_data.invoice_id,
				},
				customer_data: {
					customer_code: data.customer_data.customer_code,
					customer_name: data.customer_data.customer_name,
					pickup_from_ship_to: data.customer_data.pickup_from_ship_to,
					customer_bill_to_id: data.customer_data.customer_bill_to_id,
				},
				customer_return_data: {
					reference_code: data.customer_return_data.reference_code,
					reason_code: data.customer_return_data.reason_code,
					reason_id: data.customer_return_data.reason_id,
					customer_return_type: data.customer_return_data.customer_return_type,
					customer_return_type_id:
						data.customer_return_data.customer_return_type_id,
					territory_warehouse: data.customer_return_data.territory_warehouse,
					territory_warehouse_id:
						data.customer_return_data.territory_warehouse_id,
					note: data.customer_return_data.note,
					attachments: data.customer_return_data.attachments,
					external_code: data.customer_return_data.external_code,
					tax_id: data.customer_return_data.tax_id,
					customer_return_date: data.customer_return_data.customer_return_date,
					customer_ship_to: data.customer_return_data.customer_ship_to_id,
				},
				pickup_request: {
					is_pickup: data.pickup_request_data.is_pickup,
					pickup_date: data.pickup_request_data.pickup_date,
				},
				return_line_item_data: data.line_item_data,
				summary_data: data.summary_data,
				sales_personal_id: data.sales_personal_id,
				srID: this.srID,
			};
			this.form = form;
			
			this.onetimeCustomer.id = data.customer_data.onetime_customer_id
			this.onetimeCustomer.territory_id = data.customer_return_data.territory_id
			if (this.form.so_types != "without_so_reference"||this.isDetailForm) {
				this.onetimeCustomer.readonly = true
			}
			console.log("this.form", this.form)
		},
		async clickSaveAsDraft() {
			this.saveAsDraft = true;
			await this.clickSave();
		},
		async validateData(data) {
			console.log("data", data)
			if (data.customer_bill_to_id == null) {
				this.$vs.notify({
					title: "Error",
					text: "Customer Bill To is required",
					color: "danger",
				});
				return true;
			}
			if (data.so_types == "") {
				this.$vs.notify({
					title: "Error",
					text: "SO Type is required",
					color: "danger",
				});
				return true;
			}
			if (data.list_items.length == 0 || data.list_items == null) {
				this.$vs.notify({
					title: "Error",
					text: "Line Item is required",
					color: "danger",
				});
				return true;
			}
			if (data.external_code == null || data.external_code == "") {
				this.$vs.notify({
					title: "Error",
					text: "External Code is required",
					color: "danger",
				});
				return true;
			}

			// pickup request is required
			if (data.is_pickup == true) {
				if (data.pickup_date == null) {
					this.$vs.notify({
						title: "Error",
						text: "Pickup Date is required",
						color: "danger",
					});
					return true;
				}
			}
			if ((data.sales_personal_id == null || data.sales_personal_id == 0 || data.sales_personal_id == undefined) && data.so_type == 'without_so_reference') {
				// if (data.pickup_date == null) {
					this.$vs.notify({
						title: "Error",
						text: "Salesman is required",
						color: "danger",
					});
					return true;
				// }
			}

			// reason in list item is required
			var isReasonEmpty = false;
			for (var i = 0; i < data.list_items.length; i++) {
				if (data.list_items[i].reason == null) {
					isReasonEmpty = true;
					break;
				}
			}

			if (isReasonEmpty) {
				this.$vs.notify({
					title: "Error",
					text: "Reason in Line Item is required",
					color: "danger",
				});
				return true;
			}
		},
		async clickSave() {
			// Pengecekan QTY
			const validatedInput = await this.jsonBody(this.form);
			console.log("this.form", this.form);
			let is_complete_qty_inv = true;
			let qtyBySku = []; 
			let reasonBySku = [];
			let subtotalBySku = [];
			let msg_qty_inv = "";
			
			this.form.return_line_item_data.forEach((line_item, n) => {
				console.log("line_item", line_item)
				if (qtyBySku[line_item.sku_code] == undefined) {
					qtyBySku[line_item.sku_code] = line_item.quantity;
				} else {
					qtyBySku[line_item.sku_code] += line_item.quantity;
				}

				if (reasonBySku[line_item.sku_code] == undefined) {
					reasonBySku[line_item.sku_code] = line_item.reason;
				}

				if (subtotalBySku[line_item.sku_code] == undefined) {
					if( line_item.adj_price != null && this.form.so_types == "without_so_reference"){
						subtotalBySku[line_item.sku_code] = line_item.quantity * line_item.adj_price;
					}
					if ( line_item.price != null && this.form.so_types == "with_so_reference") {
						subtotalBySku[line_item.sku_code] = line_item.quantity * line_item.price;
					}
				} else {
					if( line_item.adj_price != null && this.form.so_types == "without_so_reference"){
						subtotalBySku[line_item.sku_code] += line_item.quantity * line_item.adj_price;
					} 
					if ( line_item.price != null && this.form.so_types == "with_so_reference") {
						subtotalBySku[line_item.sku_code] += line_item.quantity * line_item.price;
					}
				}

				// Pengecekan untuk Qty input vs Qty Invoice for sr withReff Partial
				if(this.form.so_types == "with_so_reference" && this.return_type_str == "0") {
					if (line_item.quantity > line_item.qty_invoice) {
						msg_qty_inv += line_item.sku_code + " " + line_item.unit_name + "<br>";
						is_complete_qty_inv = false;
						// reset Qty same as invQty
						this.form.return_line_item_data[n].quantity = this.form.return_line_item_data[n].qty_invoice;
					}
				}
			});
			
			let is_complete = true;
			let is_complete_reason = true;
			let is_complete_prices = true;
			let is_complete_totals = true;
			let msg = "Please add qty for these SKU : <br>";
			let msg_reason = "Please select reason for these SKU : <br>";
			let msg_prices = "Please set price or add qty for these SKU : <br>";

			qtyBySku.forEach((qty, sku_code) => {
				console.log("qty, sku_code", qty, sku_code);
				if (qty == 0) {
					msg += sku_code + "<br>";
					is_complete = false;
				}
			});

			subtotalBySku.forEach((subtotal, sku_code) => {
				console.log("subtotal, sku_code", subtotal, sku_code);
				if (subtotal <= 0) {
					msg_prices += sku_code + "<br>";
					is_complete_prices = false;
				}
			});

			reasonBySku.forEach((reason, sku_code) => {
				console.log("reason, sku_code", reason, sku_code);
				if (reason == "" || reason == null) {
					msg_reason += sku_code + "<br>";
					is_complete_reason = false;
				}
			});

			if (this.form.so_types == "without_so_reference") {
				is_complete_totals = (this.form.summary_data.total_adj_price + this.form.summary_data.total_tax > 0);
			} else if (this.form.so_types == "with_so_reference") {
				is_complete_totals = (this.form.summary_data.total_list_price + this.form.summary_data.total_tax - this.form.summary_data.total_discount > 0);
			}

			console.log("this.form", this.form);
			console.log("subtotalBySku", subtotalBySku);
			console.log("reasonBySku", reasonBySku);
			console.log("qtyBySku", qtyBySku);
			
			if ((!is_complete || !is_complete_reason || !is_complete_prices || !is_complete_totals) || !is_complete_qty_inv) {
				console.log("Throw alert here!", msg);
				console.log("Throw alert here!", msg_reason);
				console.log("Throw alert here!", msg_prices);
				if (!is_complete){
					this.$vs.notify({
						time: 10000,
						title: "Error",
						text: msg,
						color: "danger",
					});
				}
				if (!is_complete_reason){
					this.$vs.notify({
						time: 10000,
						title: "Error",
						text: msg_reason,
						color: "danger",
					});
				}
				if (!is_complete_prices){
					this.$vs.notify({
						time: 10000,
						title: "Error",
						text: msg_prices,
						color: "danger",
					});
				}
				if(!is_complete_totals){
					this.$vs.notify({
						time: 10000,
						title: "Error",
						text: "Cannot submit Sales Return with 0 or less value.",
						color: "danger",
					});
				}
				if(!is_complete_qty_inv){
					msg_qty_inv = "Quantity retur cannot greater than Invoice Quantity for SKU | HU : <br>" + msg_qty_inv;
					this.$vs.notify({
						time: 10000,
						title: "Error",
						text: msg_qty_inv,
						color: "danger",
					});
				}

				return;
			} else {
				this.$vs.loading();
				console.log("checkpoint")
				if (this.isOnetime) {
					const tax_id =
				this.form.customer_return_data.tax_id != null
					? (this.form.customer_return_data.tax_id.ID ? this.form.customer_return_data.tax_id.ID : this.form.customer_return_data.tax_id)
					: null;
					Promise.all([
						this.$refs.customerData.submitCustOnetime(tax_id)
					]).then((r) => {
						console.log("update one-time",r);
						if(r[0].code == 200) {
							this.$vs.loading.close();
							this.customerOnetimeID = r[0].data.ID
							console.log("this.customerOnetimeID,",this.customerOnetimeID)
							this.acceptSave();
						} else {
							this.$vs.loading.close();
						}
					}).catch((r)=>{
						this.$vs.loading.close();
					});
				}else{
					this.acceptSave()
				}
				
			}
		},
		async acceptSave(){
			this.$vs.loading();
			try {
				const validatedInput = await this.jsonBody(this.form);
				console.log("validatedInput", validatedInput, "form", this.form);
				if (this.$route.query.id != null || this.srID != null) {
					let id = this.$route.query.id ? this.$route.query.id : (this.srID ? this.srID : null );
					validatedInput.id = parseInt(id);
				}
				const validatedData = await this.validateData(validatedInput);
				if (validatedData) {
					this.$vs.loading.close();
					return;
				}

				validatedInput.form_status = "create";
				if (validatedInput.id != null) {
					validatedInput.form_status = "update";
				}

				let resp = await SalesReturnV2Repo.createUpdateSalesReturn(
					validatedInput
				);
				if (resp.code == 200) {
					this.$vs.notify({
						title: "Success",
						text: resp.message,
						color: "success",
					});
					this.$emit("handleClose", this.dataFil);
					// this.$router.push({
					// 	name: "sales-return-v2",
					// });
				} else {
					this.$vs.notify({
						time: 10000,
						title: "Error",
						text: resp.message,
						color: "danger",
					});
				}
			} catch (error) {
				this.$vs.notify({
					time: 10000,
					title: "Error",
					text: error.message,
					color: "danger",
				});
				console.log(error);
			} finally {
				this.$vs.loading.close();
			}
		},
		async jsonBody(form) {
			const id = parseInt(this.form.id) || null;
			const sales_order_id =
				this.form.customer_invoice_data.sales_order_id || null;
			const sales_personal_id = form.so_types == 'with_so_reference' ? (this.form.customer_invoice_data.sales_id != null ? this.form.customer_invoice_data.sales_id : null) : this.form.sales_personal_id;
			let invoice_id = this.form.customer_invoice_data.invoice_id || null;
			if (this.form.customer_invoice_data.customer_invoice_id) {
				invoice_id = this.form.customer_invoice_data.customer_invoice_id;
			}

			const customer_id = this.form.customer_data.selected_customer_id ? this.form.customer_data.selected_customer_id : ( this.form.customer_data.customer_sold_to.customer_id ? this.form.customer_data.customer_sold_to.customer_id : null );
			const customer_return_type_id =
				this.form.customer_return_data.customer_return_type != null
					? this.form.customer_return_data.customer_return_type.ID
					: null;
			const pickup_id =
				this.form.customer_data.pickup_from_ship_to != null
					? this.form.customer_data.pickup_from_ship_to.ID
					: null;

			const reference_code =
				this.form.customer_return_data.reference_code || null;
			const reason_code_id =
				this.form.customer_return_data.reason_code != null
					? this.form.customer_return_data.reason_code.ID
					: null;
			const warehouse_territory_id =
				this.form.customer_return_data.territory_warehouse != null
					? this.form.customer_return_data.territory_warehouse.id
					: null;
			const note = this.form.customer_return_data.note || null;
			const customer_return_date = this.form.customer_return_data.customer_return_date || null;
			const is_pickup =
				this.form.pickup_request.is_pickup == "true" ? true : false;
			const pickup_date = this.form.pickup_request.pickup_date || null;

			// update child item to be same reason and attachments with parent by sku_code same, detect parent in item.parent = true
			/*
			MIX2-5452 | attachment dan reason dipisahkan tiap item HU, tiap item HU bisa punya sendiri-sendiri
			Tidak perlu disamakan dengan parent
			for (var i = 0; i < this.form.return_line_item_data.length; i++) {
				if (this.form.return_line_item_data[i].parent == true) {
					for (var j = 0; j < this.form.return_line_item_data.length; j++) {
						if (
							this.form.return_line_item_data[i].sku_code ==
							this.form.return_line_item_data[j].sku_code
						) {
							this.form.return_line_item_data[j].reason =
								this.form.return_line_item_data[i].reason;
							this.form.return_line_item_data[j].attachments =
								this.form.return_line_item_data[i].attachments;
						}
					}
				}
			}
			*/

      // sini
			const list_items = this.form.return_line_item_data.map((item) => {
				let tax_id = this.form.so_types == "without_so_reference" ? (item.tax.ID ? item.tax.ID : null) : this.form.customer_return_data.tax_id.ID
				let listItem = {
					id: item.id,
					invoice_line_id: item.invoice_line_id,
					item_name: item.item_name,
					unit_name: item.unit_name,
					qty_invoice: parseFloat(item.qty_invoice),
					so_price: parseFloat(item.so_price),
					price: parseFloat(item.price),
					adj_price: parseFloat(item.adj_price),
					reason: item.reason,
					attachments: item.attachments,
					parent: item.parent,
					tax_id: tax_id,
					//this.form.so_types == "without_so_reference" ? (item.tax.ID ? item.tax.ID : null) : this.form.customer_return_data.tax_id.ID,
					// tax_id: item.tax[0].ID !== undefined ? item.tax[0].ID : (item.tax.ID !== undefined ? item.tax.ID : null),
				};

				if (item.sku_code != null) {
					listItem.sku_code = item.sku_code;
				} else {
					listItem.sku_code = item.item_sku_code;
				}
				if (item.quantity != null) {
					listItem.qty = parseFloat(item.quantity);
				} else {
					listItem.qty = parseFloat(item.qty);
				}
				if (item.tax != null) {
					listItem.tax_amount = parseFloat(item.tax);
				} else {
					listItem.tax_amount = parseFloat(item.taxAmount);
				}
        
				listItem.tax_rate = parseFloat(item.taxRate);
				if(this.form.so_types != "without_so_reference"){
					listItem.tax_rate = this.form.customer_return_data.tax_id.Rate
				} 

				if (item.reg_discount != null) {
					listItem.reg_discount = parseFloat(item.reg_discount);
				} else {
					listItem.reg_discount = parseFloat(item.discount);
				}
				if (item.unit_name != null) {
					listItem.unit_name = item.unit_name;
				} else {
					listItem.unit_name = item.item_unit;
				}
				
				if(item.adj_price != 0 || item.price !=0) {
				  let price = item.adj_price
				  if(price == 0) {
					price = item.price
				  }
				  listItem.total = parseFloat(price) * parseFloat(item.quantity) - parseFloat(item.discount) + parseFloat(listItem.tax_amount)
				}

				return listItem;
			});

			const attachments = this.form.customer_return_data.attachments || [];
			const save_as_draft = this.saveAsDraft || false;
			const total_price = this.form.summary_data.total_list_price;
			const customer_sold_to =
				this.form.customer_data.customer_sold_to != null
					? this.form.customer_data.customer_sold_to.ID
					: null;
			const customer_ship_to =
				this.form.customer_return_data.customer_ship_to != null
					? this.form.customer_return_data.customer_ship_to
					: null;
			const external_code =
				this.form.customer_return_data.external_code || null;
			const tax_id =
				this.form.customer_return_data.tax_id != null
					? (this.form.customer_return_data.tax_id.ID ? this.form.customer_return_data.tax_id.ID : this.form.customer_return_data.tax_id)
					: null;

			return {
				id: id,
				so_type: form.so_types,
				sales_order_id: sales_order_id,
				sales_personal_id: sales_personal_id,
				invoice_id: invoice_id,
				customer_id: customer_id,
				sales_return_type_id: customer_return_type_id,
				pickup_id: pickup_id,
				reference_code: reference_code,
				reason_code_id: reason_code_id,
				warehouse_territory_id: warehouse_territory_id,
				note: note,
				is_pickup: is_pickup,
				pickup_date: pickup_date,
				customer_return_date: customer_return_date,
				list_items: list_items,
				attachments: attachments,
				save_as_draft: save_as_draft,
				total_price: total_price,
				customer_bill_to_id: customer_sold_to,
				customer_ship_to_id: customer_ship_to,
				external_code: external_code,
				customer_onetime_id:this.isOnetime?this.customerOnetimeID:0,
				tax_id: tax_id,
			};
		},
		async setCustomerInvoiceData(data) {
			console.log("data", data)
			console.log("data territory", data.salesOrderData.TerritoryID)
			console.log("data OnetimeCustomerID", data.salesOrderData.OnetimeCustomerID)
			this.onetimeCustomer.id = data.salesOrderData.OnetimeCustomerID
			this.onetimeCustomer.territory_id = data.salesOrderData.TerritoryID
			if (this.terr_params==null) {
				this.terr_params = { 
					params : {
						id : data.salesOrderData.TerritoryID,
					},
				};
			}
			if(data != null){
				this.form.customer_invoice_data.customer_invoice_id = data.invoiceID;
				this.form.customer_invoice_data.sales_order_id = data.salesOrderID;
				this.form.customer_invoice_data.sales_id = data.salesID;
				this.form.customer_data.customer_id = data.customerIDs;
				this.form.customer_invoice_data.invoice_id = data.invoiceID;
				this.form.customer_invoice_data.invoice_lines = data.invoiceLines;
				this.form.customer_invoice_data.invoice = data.invoice;
				this.form.customer_invoice_data.sales_order_data = data.salesOrderData ? data.salesOrderData : null;
	
				if (data.invoice.InvoiceLines.length == 0) {
					this.$vs.notify({
						title: "Error",
						text: "Invoice lines item is empty",
						color: "danger",
					});
	
					return;
				}
	
				// // set total remaining invoicelines
				var total_remaining = 0;
				for (var i = 0; i < data.invoiceLines.length; i++) {
					total_remaining += data.invoiceLines[i].Subtotal;
				}
				this.form.customer_invoice_data.invoice_total_remaining = total_remaining;
			}
		},
		// async setSelectSalesman(sales) {
		setSelectSalesman(sales) {
			console.log("this.form", this.form)
			console.log("sales", sales)
			if(sales==null && this.form.so_types == "without_so_reference"){
				this.form.sales_personal_id = null;
				this.$vs.notify({
					title: "Error",
					text: "Please select salesman !",
					color: "danger",
				});
			} else {
				this.form.sales_personal_id = sales.ID;
				if(this.form.so_types == "without_so_reference"){
					this.form.sales_personal_id = sales.ID;
					console.log("this.form", this.form)
				}
			}
			console.log("this.form", this.form)
		},
		async clickBack() {
            this.$emit("handleClose", this.dataFil);
		},
		setOneTime(v){
			this.isOnetime = v
		},
	},
	watch: {
		"form.so_types": {
			handler: function (val) {
				if (val == "with_so_reference") {
					this.isDisabledFormInvoice = false;
					this.so_type_object_selected = this.so_type_object[0];
				} else {
					this.isDisabledFormInvoice = true;
					this.so_type_object_selected = this.so_type_object[1];
				}
			},
		},
		so_type_object_selected: {
			handler: function (val) {
				if (val&&val.code == "with_so_reference") {
					this.isDisabledFormInvoice = false;
					this.form.so_types = val.code;
					this.onetimeCustomer.readonly = true
					if(this.srID < 1){
						if (this.form.isEdit) {
							this.onetimeCustomer.id = 0
							this.onetimeCustomer.territory_id = 0
						}
						this.$refs.customerData.setOneTime(false)
					}
				} else {
					if (this.form.isEdit) {
						this.onetimeCustomer.readonly = false
					}
					this.isDisabledFormInvoice = true;
					this.form.so_types = val.code;
					if(this.srID < 1){
						this.onetimeCustomer.id = 0
						this.onetimeCustomer.territory_id = 0
						if (this.terr_params!=null) {
							this.terr_params = { 
								params : {
									id : 0,
								},
							};
						}
					}
				}
			},
		},
	},
};
</script>
